import React, { useContext, useEffect } from 'react'
import Sidebar from './Sidebar'
import { useSettingsStats } from '../hooks/useState';
import { BaseContext } from '../context/BaseProvider';
import Footer from './Footer';



export default function Layout({ children }) {
    const settingStats = useSettingsStats(1);
    const { sidebar } = useContext(BaseContext)
    

    return (
        <div className={`App g-sidenav-show ${sidebar ? 'g-sidenav-pinned' : ''}`}>
            <Sidebar settingStats={settingStats} />
            <main class={`main-content position-relative border-radius-lg `}>
                {children}
            </main>
            <Footer />
        </div>
    )
}
