import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { bscTestnet , sepolia , polygonMumbai, bsc, polygon , mainnet } from 'wagmi/chains';
import { PROJECT_ID } from './constant';


const chains = [ polygonMumbai , sepolia , bscTestnet];
// const chains = [ bsc , polygon , mainnet];


export const config = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: PROJECT_ID,
  chains,
  ssr: true, // If your dApp uses server side rendering (SSR)
});