import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { config } from './helper/wagmi';
import { BaseProvider } from './context/BaseProvider';
import store from './redux/store';
import { Provider } from 'react-redux';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import { WagmiProvider } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';



const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BaseProvider>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <App />
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </BaseProvider>
    </Provider>
  </React.StrictMode>
);

