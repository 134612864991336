import React from 'react';

export default function MultisenderHeadSection({ settingStats }) {

    return (
        <div className='row d-flex justify-content-center align-items-center py-4'>
            <div className='col-12 col-md-8 text-center'>
                <h3 className='text-white mt-2 mt-md-0 mb-md-0 text-bold text-uppercase'>TOKEN / NFT SENDER</h3>
                <p className='text-success text-bold mb-1 mb-md-0 text-lg'>Transfer 10,000 address in 5 minutes</p>
            </div>
        </div>
    )
}
