import React from "react";
import { useAccount } from 'wagmi';
import { useConnectModal, useAccountModal } from '@rainbow-me/rainbowkit';
import { trimAddress } from "../helper/constant";




export const Connect = function () {
    const { address, isConnected } = useAccount()
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();


    return (
        <React.Fragment>

            <>
                {address && isConnected ? (

                    <button className='bg-warning btn btn-primary btn-sm mb-0 me-1 p-2 px-4 text-dark fs-14' onClick={() => openAccountModal()}>
                        <i class="fa-solid fa-wallet mx-2"></i>
                        {trimAddress(address)}
                    </button>

                ) : (
                    <button className='bg-warning btn btn-primary btn-sm mb-0 me-1 p-2 px-4 text-dark fs-14' onClick={() => openConnectModal()}>
                        <i class="fa-solid fa-wallet mx-2"></i>
                        Connect
                    </button>
                )
                }

            </>

        </React.Fragment >
    );
};

export default Connect;