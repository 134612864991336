import React, { useMemo, useState } from 'react'
import CodeMirror from "@uiw/react-codemirror";
import { fetchAddressValidation, fetchStep1Validation, fetchTokenInfo, updateIsDrop, updateIsExclude, updateStep1Validation } from '../redux/slices/MultisenderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { DEFAULT_CHAIN, MULTISENDER_CHAIN, contract, trimAddress } from '../helper/constant';
import { Oval } from 'react-loader-spinner';
import { useDropzone } from 'react-dropzone';
import { ExcelRenderer } from "react-excel-renderer";
import { toast } from 'react-toastify';
import ExampleModal from './modal/ExampleModal';
import Step1InvalidModal from './modal/Step1InvalidModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check'; // Icon for "Copied" state
import Checkbox from '@mui/material/Checkbox';


export default function SenderStep1() {
    const dispatch = useDispatch();
    const { address, isConnected, chain, chainId } = useAccount();

    const tokenAddress = useSelector(state => state.multisender.tokenAddress)
    const tokenLoader = useSelector(state => state.multisender.tokenLoader)
    const tokenInfo = useSelector(state => state.multisender.tokenInfo)
    const nftInfo = useSelector(state => state.multisender.nftInfo)
    const tokenError = useSelector(state => state.multisender.tokenError)
    const isDrop = useSelector(state => state.multisender.isDrop)
    const step1Validation = useSelector(state => state.multisender.step1Validation)
    const listAddressError = useSelector(state => state.multisender.listAddressError)
    const step1ErrorModal = useSelector(state => state.multisender.step1ErrorModal)
    const isExcluded = useSelector(state => state.multisender.isExcluded)

    const listAddress = useSelector(state => state.multisender.listAddress)
    const [show, setShow] = useState(false);
    const [isBlur, setIsBlur] = useState(false);

    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false); // Reset the icon after 2 seconds
        }, 2000);
    };

    // const textToCopy = contract[chainId ? chainId : DEFAULT_CHAIN].MULTISENDER_ADDRESS;
    const textToCopy = '';

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const fileReader = new FileReader();
            if (acceptedFiles[0]) {
                let fileExSplit = acceptedFiles[0].name ? acceptedFiles[0].name.split('.') : [];
                let fileType = fileExSplit[fileExSplit.length - 1];

                fileReader.onload = function (event) {
                    if (fileType === 'txt' || fileType === 'csv') {
                        dispatch(fetchAddressValidation({
                            data: event.target.result,
                            chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN
                        }))
                    }
                    else if (fileType === 'xlsx') {
                        let excelData = '';
                        ExcelRenderer(acceptedFiles[0], (err, resp) => {
                            if (err) {
                                toast.error(err);
                            } else {
                                const { rows } = resp;

                                if (rows && rows.length > 0) {
                                    Promise.all(rows.filter((rowdata) => {
                                        if (rowdata[0] && rowdata[1]) {
                                            excelData += `${rowdata[0]},${rowdata[1]}\r\n`;
                                        }
                                        return true
                                    })).then(() => {
                                        dispatch(fetchAddressValidation({
                                            data: excelData,
                                            chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN
                                        }))
                                    })
                                }
                                else {
                                    toast.error('data not found in correct format or may some error in upload data!!');
                                }
                            }
                        });
                    }
                    else {
                        toast.error('file not supported!!');
                    }
                };
                fileReader.readAsText(acceptedFiles[0]);
            }
        }
        else {
            toast.error('file not found , try again!!');
        }
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleNextStep = async () => {
        if (listAddress && (tokenAddress === '' || (tokenInfo.name && tokenInfo.symbol && tokenInfo.decimals && tokenInfo.balance) || (nftInfo.name && nftInfo.symbol))) {
            dispatch(updateStep1Validation());
            dispatch(fetchStep1Validation({
                chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN,
                address: address
            }))
        }
        else {
            toast.error('please enter valid details');
        }
    }

    const handleCheckboxChange = (event) => {
        dispatch(updateIsExclude(event.target.checked)); // Toggle checkbox state
    };

    const nativeSymbol = useMemo(() => {
        return `${chain && chain.id && MULTISENDER_CHAIN.includes(chain.id) ? contract[chain.id].symbol : contract[DEFAULT_CHAIN].symbol} - Native Currency`
    }, [chain]);


    return (
        <div class="container">
            <div class="mb-3">
                <div className='d-flex justify-content-between'>
                    <label for="address" class="form-label">Token Address</label>
                </div>
                <div class="input-container relative">
                    <input type="text"
                        onBlur={() => {
                            setIsBlur(false);
                        }}
                        onFocus={() => {
                            setIsBlur(true)
                        }}
                        onChange={(e) => {
                            dispatch(fetchTokenInfo(
                                {
                                    tokenAddress: e.target.value,
                                    chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN,
                                    address: isConnected && address ? address : false
                                }))
                        }} value={tokenAddress ? tokenAddress : !isBlur ? nativeSymbol : ''} class="form-control" id="address" placeholder='eg. 0x00000000000000000000 or Leave Blank for ETH ' />
                    <div class="dropdown absolute top-full z-index-2 w-full left-0 bg-card  border border-gray-300 mt-2 p-2 rounded">
                        <p class="dropdown-item" onClick={() => dispatch(fetchTokenInfo(
                            {
                                tokenAddress: '',
                                chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN,
                                address: isConnected && address ? address : false
                            }))}>
                            {nativeSymbol}
                        </p>

                    </div>
                </div>
                {tokenError && <p className='text-danger text-sm'>{tokenError}</p>}
                <div className='d-flex justify-content-between mx-2 mt-1 mb-4'>
                    {tokenLoader &&
                        <Oval
                            visible={true}
                            height="20"
                            width="20"
                            color="#4fa94d"
                            ariaLabel="oval-loading"
                            wrapperClass=""
                        />
                    }
                    {tokenInfo && tokenInfo.name && tokenInfo.symbol &&
                        <>
                            <span className='font-bold'>{tokenInfo.name}({tokenInfo.symbol})</span>
                            <span className='font-bold'>Balance: {(tokenInfo.balance)}</span>
                        </>
                    }
                    {nftInfo && nftInfo.name && nftInfo.symbol &&
                        <>
                            <span className='font-bold'>{nftInfo.name}({nftInfo.symbol})</span>
                            <span className='font-bold'>Balance: {(nftInfo.balance)}</span>
                        </>
                    }
                </div>
            </div>
            <div class="mb-3">
                <div className='d-flex justify-content-between mb-3'>
                    <label for="exampleInputPassword1" class="form-label">List of addresses</label>
                    <button type="button" class="bg-warning btn btn-primary btn-sm mb-0 mb-1 p-1 px-2 text-dark" onClick={() => dispatch(updateIsDrop())}>
                        {isDrop ? 'Insert manually' : 'File Upload'}
                    </button>
                </div>
                {isDrop ? (
                    <div {...getRootProps({ className: 'dropzone' })} className='p-4 rounded-3 rounded-xl border-input' style={{ 'height': "200px" }}>
                        <input {...getInputProps()} />
                        <div class="content text-center">
                            <p>
                                <svg viewBox="0 0 24 24" width="52" height="52" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                            </p>
                            <strong style={{ "color": "#fff" }}>Drop your files here or <strong>click to upload</strong></strong><br />
                            <em className='font-color'>(Only *.csv,*.xlsx,*.txt file will be accepted)</em>
                        </div>
                    </div>

                ) : (
                    <CodeMirror
                        height="200px"
                        theme={'dark'}
                        placeholder="Insert address and amount,separate with comma"
                        style={{
                            border: "1px solid #444444",
                        }}
                        onChange={(e) => dispatch(fetchAddressValidation({ data: e, chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN }))}
                        value={listAddress}
                    />
                )}
                {listAddressError && <p className='text-danger'>{listAddressError}</p>}
            </div>
            <div className='mb-3'>
                <div className='d-flex justify-content-between'>
                    <span className='text-white'>{isDrop ? 'Accepted: CSV / Excel / Txt' : 'Separated by commas'}</span>

                    <button type="button" class="bg-card btn btn-primary btn-sm mb-0 mb-1 py-2 px-2 text-white" onClick={() => setShow(true)}>
                        Show examples
                    </button>

                    <ExampleModal show={show} setShow={setShow} isDrop={isDrop} />
                </div>
            </div>
            <div className='d-flex flex-wrap'>

                <p className='my-2'>

                    Please exclude Airdrop address {trimAddress(textToCopy)}{" "}
                    <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
                        <button style={{ color: "#ff", background: 'none', border: 'none', cursor: 'pointer' }}>
                            {isCopied ? (
                                <CheckIcon fontSize="small" sx={{ color: 'green' }} />
                            ) : (
                                <ContentCopyIcon sx={{ color: 'white' }} fontSize="small" />
                            )}
                        </button>
                    </CopyToClipboard>{" "}
                    from fees, rewards, max tx amount
                </p>
                <p className='my-2'>
                    <Checkbox
                        style={{ color: "#ffe804", marginRight: "5px" }}
                        checked={isExcluded}
                        onChange={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'Airdrop Address Checkbox' }}
                    />
                    if you don't want to exclude
                </p>
            </div>
            <button type="button" disabled={step1Validation} onClick={() => handleNextStep()} class="btn text-dark bg-warning w-full">
                {step1Validation ? 'Loading...' : 'Continue'}
            </button>

            <Step1InvalidModal show={step1ErrorModal} />
        </div>
    )
}
