import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Home from "./pages/Home";
import Multisender from "./pages/Multisender";
// import Admin from "./pages/Admin";
// import Landing from "./pages/Landing";
import Layout from "./components/Layout";

function App() {

  return (

    <BrowserRouter>
      <ToastContainer pauseOnFocusLoss={false} />


      <Routes>

        {/* <Route exact path="/" element={<Layout><Home /></Layout>} /> */}
        <Route exact path="/" element={<Layout><Multisender /></Layout>} />
        {/* <Route exact path="/admin" element={<Layout><Admin /></Layout>} /> */}

      </Routes>


    </BrowserRouter>



  );
}

export default App;
