import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStep2Validation, updateAllowance } from '../redux/slices/MultisenderSlice';
import { formatPrice, getContract } from '../helper/contractHelper';
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';
import { MULTISENDER_CHAIN, contract, getWeb3 } from '../helper/constant';
import tokenAbi from '../json/token.json';
import nftAbi from '../json/nft.json';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { ethers } from 'ethers';

export default function SenderStep2() {
  const dispatch = useDispatch();
  const { address, isConnected , chain } = useAccount();
  const signer = useEthersSigner();
  const tokenInfo = useSelector(state => state.multisender.tokenInfo)
  const ethInfo = useSelector(state => state.multisender.ethInfo)
  const nftInfo = useSelector(state => state.multisender.nftInfo)
  const tokenType = useSelector(state => state.multisender.tokenType)
  const numberofaddress = useSelector(state => state.multisender.numberofaddress)
  const tokenAddress = useSelector(state => state.multisender.tokenAddress)
  const totalTokenSent = useSelector(state => state.multisender.totalTokenSent);
  const step2ErrorMessage = useSelector(state => state.multisender.step2ErrorMessage);
  const step2Validation = useSelector(state => state.multisender.step2Validation);
  const [loading, setLoading] = useState(false);

  console.log(tokenType)


  const handleApprove = async (id) => {
    setLoading(true);
    try {
      if (address && isConnected) {
        if (chain && MULTISENDER_CHAIN.includes(chain.id)) {
          let tx;
          let approveTokenLimit = 0
          if (id === 3) {
            let tokencontract = getContract(nftAbi, tokenAddress, signer);
            tx = await tokencontract.setApprovalForAll(contract[chain.id].MULTISENDER_ADDRESS, true, { 'from': address });
          }
          else {
            let tokencontract = getContract(tokenAbi, tokenAddress, signer);
            approveTokenLimit = parseFloat(totalTokenSent) + 10000;
            let weiAmount = ethers.utils.parseUnits(approveTokenLimit.toString(), tokenInfo.decimals);
            tx = await tokencontract.approve(contract[chain.id].MULTISENDER_ADDRESS, weiAmount, { 'from': address });
          }
          toast.loading('Waiting for confirmation');

          var interval = setInterval(async function () {
            let web3 = getWeb3(chain.id);
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval)
              if (response.status === true) {

                dispatch(updateAllowance(approveTokenLimit));
                toast.dismiss()
                toast.success('success ! your last transaction is success 👍');
                setLoading(false);
              }
              else if (response.status === false) {
                toast.dismiss()
                toast.error('error ! Your last transaction is failed.');
                setLoading(false);
              }
              else {
                toast.dismiss()
                toast.error('error ! something went wrong.');
                setLoading(false);
              }
            }
          }, 5000);
        }
        else {
          toast.dismiss()
          toast.error('selected chain not supported');
          setLoading(false);
        }
      }
      else {
        toast.dismiss()
        toast.error('please connect wallet');
        setLoading(false);
      }
    }
    catch (err) {
      toast.dismiss()
      toast.error(err.reason ? err.reason : err.message);
      setLoading(false);
    }
  }

  const handleNextStep = async () => {
    dispatch(fetchStep2Validation(
      {
        chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN,
        address: address
      }
    ))
  }

  return (
    // <div className='bg-body-card max-w-3xl mt-1 mx-auto rounded-3 rounded-xl border-input'>
    //   <div className='p-0 p-xl-5'>
    <>
      <div class="grid gap-5">
        <div class="grid grid-cols-2 grid-cols-1 gap-3">
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">
              {tokenAddress && tokenType === 2
                ? formatPrice(tokenInfo.allowance) :
                tokenAddress && tokenType === 3 ?
                  nftInfo.allowance ? '∞' : '0 '
                  : '∞'} {" "}
              {tokenAddress && tokenType === 2 ?
                tokenInfo.symbol : tokenAddress && tokenType === 3 ?
                  nftInfo.symbol : ethInfo.symbol}
            </span>
            <span class="text-sm text-muted-foreground">Your current multisend allowance</span>
          </div>
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">
              {
                tokenType === 3 ? formatPrice(numberofaddress, 4) : formatPrice(totalTokenSent, 6)} {" "}
              {tokenAddress && tokenType === 2 ? tokenInfo.symbol : tokenAddress && tokenType === 3 ? nftInfo.symbol : ethInfo.symbol}
            </span>
            <span class="text-sm text-muted-foreground">Total number of tokens to be sent</span>
          </div>
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">
              {tokenAddress && tokenType === 2 ? formatPrice(tokenInfo.balance) :
                tokenAddress && tokenType === 3 ? formatPrice(nftInfo.balance) : formatPrice(ethInfo.balance, 4)} {" "}
              {tokenAddress && tokenType === 2 ? tokenInfo.symbol : tokenAddress && tokenType === 3 ? nftInfo.symbol : ethInfo.symbol}
            </span>
            <span class="text-sm text-muted-foreground">Your token balance</span>
          </div>
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">{ethInfo ? formatPrice(ethInfo.balance, 4) : ' - '} {ethInfo ? ethInfo.symbol : ' - '} </span>
            <span class="text-sm text-muted-foreground">Your {ethInfo ? ethInfo.symbol : ' - '} balance</span>
          </div>
        </div>
      </div>
      {loading &&
        <div class="p-3 text-sm text-green-500 rounded-xl bg-green-500/20 mt-3 ">Preparing transactions...</div>
      }
      {
        step2ErrorMessage &&
        <div class="p-3 text-sm text-danger rounded-xl mt-3 ">{step2ErrorMessage}</div>
      }
      {tokenAddress !== '' && tokenType === 2 && parseFloat(tokenInfo.allowance) < parseFloat(parseFloat(totalTokenSent)) ? (
        <button type="button" onClick={() => handleApprove(2)} disabled={loading} class="btn text-dark bg-warning w-full mt-3">
          {loading ? 'Loading...' : 'Approve'}
        </button>
      ) : tokenAddress !== '' && tokenType === 3 && !nftInfo.allowance ? (
        <button type="button" disabled={loading} onClick={() => handleApprove(3)} class="btn text-dark bg-warning w-full mt-3">
          {loading ? 'Loading...' : 'Approve'}
        </button>
      ) : (
        <button type="button" onClick={() => handleNextStep()} disabled={step2Validation} class="btn text-dark bg-warning w-full mt-3">
          {step2Validation ? 'Loading...' : 'Continue'}
        </button>
      )}
      {/* </div>
    </div > */}
    </>
  )
}
