import React, { useMemo, useState } from 'react'
import Header from '../components/Header';
import MultisenderHeadSection from '../components/MultisenderHeadSection';
import MultisenderStep from '../components/MultisenderStep';
import SenderStep1 from '../components/SenderStep1';
import SenderStep2 from '../components/SenderStep2';
import { useSelector } from 'react-redux';
import SenderStep3 from '../components/SenderStep3';
import SenderStep4 from '../components/SenderStep4';
import youtubeIcon from '../images/youtube.png';
import downloadIcon from '../images/download.png';
import Modal from 'react-bootstrap/Modal';
import YouTube from 'react-youtube';
import { useSettingsStats } from '../hooks/useState';

export default function Multisender() {
  const currentStep = useSelector(state => state.multisender.step);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const settingStats = useSettingsStats(1);
  const videoId = useMemo(() => {
    return settingStats && settingStats.multisender_youtube_video_id ? settingStats.multisender_youtube_video_id : ''
  }, [settingStats]);

  const opts = {
    width: '100%',
    height: "400px"
  };


  return (
    <>
      <Header />
      <div className='container-fluid'>
        <MultisenderHeadSection settingStats={settingStats} />
        <div className='bg-card mb-5 mt-3 py-3 py-md-5'>
          <MultisenderStep />
          <div className='bg-body-card max-w-3xl mt-1 mx-auto rounded-3 rounded-xl border-input'>
            <div className='pb-2 pt-5 px-2 px-md-5'>
              {currentStep === 1 &&
                <SenderStep1 />
              }
              {currentStep === 2 &&
                <SenderStep2 />
              }
              {currentStep === 3 &&
                <SenderStep3 />
              }
              {currentStep === 4 &&
                <SenderStep4 />
              }

              <div className='d-flex justify-content-center mb-3'>
                <div className='mx-0 mx-md-3 cursor-pointer' onClick={handleShow}>
                  <img src={youtubeIcon} height="24px" width="24px" alt='youtube' className='mx-2' /><span className='font-color text-bold'>How to send</span>
                </div>
                <div className='mx-0 mx-md-3 cursor-pointer'>
                  <a href={settingStats && settingStats.download_wallet_link ? `${settingStats.download_wallet_link}` : '#sec'} download target='_blank'>
                    <img src={downloadIcon} height="24px" width="24px" alt='youtube' className='mx-2' /><span className='font-color text-bold'>Download wallet</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}>
          <Modal.Body>
            <YouTube opts={opts} videoId={videoId} />
          </Modal.Body>

        </Modal>
      </div>

    </>
  )
}
